<template>
  <div>
    <a-modal
      :visible="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ formData.id ? $t("编辑账号") : $t("新增账号") }}</div>
      <div>
        <a-form-model ref="form" :model="formData" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
          <a-form-model-item prop="username" :label="$t('用户名')">
            <a-input v-model="formData.username" />
          </a-form-model-item>
          <a-form-model-item prop="name" :label="$t('姓名')">
            <a-input v-model="formData.name" />
          </a-form-model-item>
          <a-form-model-item prop="phone" :label="$t('电话')">
            <a-input v-model="formData.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" :label="$t('邮箱')">
            <a-input v-model="formData.email" />
          </a-form-model-item>
          <a-form-model-item prop="is_active" :label="$t('状态')">
            <a-select v-model="formData.is_active" style="width: 100%">
              <a-select-option :value="true">{{ $t("激活") }}</a-select-option>
              <a-select-option :value="false">{{ $t("冻结") }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item v-if="!formData.is_manager" prop="roles" :label="$t('角色')">
            <a-select v-model="formData.roles" mode="multiple" allowClear style="width: 100%">
              <a-select-option v-for="item in roleItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="!formData.is_manager" prop="warehouses" :label="$t('授权仓库')">
            <WarehouseMultSelect v-model="formData.warehouses" :placeholder="$t('可管理授权仓库数据')" />
          </a-form-model-item>
          <a-form-model-item prop="default_warehouse" :label="$t('默认仓库')">
            <a-select
              v-model="formData.default_warehouse"
              :placeholder="$t('单据及库位平面图默认仓库')"
              allowClear
              style="width: 100%"
            >
              <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="default_client" :label="$t('默认货主')">
            <client-select
              v-model="formData.default_client"
              :placeholder="$t('单据默认货主')"
              @change="changeClient"
            ></client-select>
          </a-form-model-item>
        </a-form-model>

        <div v-if="!formData.id" style="color: rgb(255, 77, 79); text-align: center">
          {{ $t("默认初始密码为: 123456, 登录后请修改密码") }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { userCreate, userUpdate } from "@/api/account";
import { warehouseOption } from "@/api/option";
import rules from "./rules.js";

export default {
  components: {
    WarehouseMultSelect: () => import("@/components/WarehouseMultSelect"),
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  props: ["visible", "form", "roleItems"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
      warehouseItems: [],
      formData: {},
    };
  },
  methods: {
    changeClient() {
      console.log(this.formData);
    },
    initData() {
      this.formData = { ...this.form };
      if (this.formData.default_warehouse == null) {
        this.formData.default_warehouse = undefined;
      }
      if (this.formData.default_client == null) {
        this.formData.default_client = undefined;
      }
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.formData.id ? userUpdate : userCreate;
          func(this.formData)
            .then((data) => {
              this.$message.success(this.formData.id ? this.$t("修改成功") : this.$t("新增成功"));
              this.$emit(this.formData.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.initData();
      }
    },
  },
};
</script>

<style scoped></style>
